import React from "react";
import Unity, { UnityContent } from "react-unity-webgl";

export class Game extends React.Component {
  constructor(props) {
    super(props);

    this.unityContent = new UnityContent(
      "game/roast-chicken-game.json",
      "game/UnityLoader.js"
    );
  }

  componentWillUnmount() {
    this.unityContent = null;
  }

  render() {
    return <div
              style={{
                position: "fixed",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%"
              }}
            >
      <Unity unityContent={this.unityContent} width="100%" height="100%" />
    </div>;
  }
}

import React, { useState, useEffect  } from 'react';
import { Game } from './Game';
// import { Connection } from '@solana/web3.js';
import toast, { Toaster } from 'react-hot-toast';
import { useMediaQuery } from 'react-responsive'
import './App.css';
import twitter from './twitter.svg';
import dexscreener from './dexscreener.svg';
// import coingecko from './coingecko.svg';
import jupiter from './jupiter.svg';

const CA = process.env.REACT_APP_CA;
const twitterLink = process.env.REACT_APP_TWITTER;
const dexscreenerLink = process.env.REACT_APP_DEXSCREENER;
// const coingeckoLink = process.env.REACT_APP_COINGECKO;
const jupiterLink = process.env.REACT_APP_JUPITER;
const notify = (msg) => toast(msg);

function App() {
  // const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1224px)' });
  // const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' });
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });
  const isPortrait = useMediaQuery({ query: '(orientation: portrait)' });

  // const [connection, setConnection] = useState(null);
  // const [connectionStatus, setConnectionStatus] = useState('Disconnected');

  // useEffect(() => {
  //   const solanaConnection = new Connection(process.env.REACT_APP_RPC);
  //   setConnection(solanaConnection);

  //   solanaConnection.getVersion()
  //     .then(() => setConnectionStatus('Connected'))
  //     .catch((error) => {
  //       console.error('Failed to connect to Solana network:', error);
  //       setConnectionStatus('Failed to connect');
  //     });

  //   return () => {
  //     setConnection(null);
  //     setConnectionStatus('Disconnected');
  //   };
  // }, []);

  useEffect(() => {
    const handleResize = () => {
      window.location.reload();
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // useEffect(() => {
  //     window.Jupiter.init({
  //       displayMode: "widget",
  //       endpoint: process.env.REACT_APP_RPC,
  //       defaultExplorer: "Solscan",
  //       strictTokenList: false,
  //       formProps: {
  //         fixedInputMint: false,
  //         fixedAmount: false,
  //         fixedOutputMint: true,
  //         initialOutputMint: process.env.REACT_APP_JUPITER_OUTPUT_MINT,
  //         initialSlippageBps: 700
  //       },
  //       platformFeeAndAccounts: {
  //         referralAccount: process.env.REACT_APP_JUPITER_REFERRAL_KEY,
  //         feeBps: process.env.REACT_APP_JUPITER_SWAP_FEE_BPS,
  //       },
  //       containerStyles: { 
  //         zIndex: 600,
  //       },
  //       onSuccess: ({ txid, swapResult }) => {
  //         const formattedAmount = swapResult?.outputAmount / Math.pow(10, 5);
  //         notify(`Bagged $ROAST ${formattedAmount.toFixed(5)} crispy coins!`);
  //       },
  //       onSwapError: ({ error }) => {
  //         notify(`Oh cluck!:\n${error?.error?.message === undefined ? 'Something went wrong' : error?.error?.message}`);
  //       },
  //     });
  // }, []);
  
  const [caText, setCAText] = useState(CA);

  const caClickEvent = () => {
    setCAText('Copied!');
    navigator.clipboard.writeText(CA);

    setTimeout(() => {
      setCAText(CA);
    }, 600);
  };

  return (
    <div className={`App-${isTabletOrMobile ? 'mobile': 'desktop'}`}>
      <header className="App-header">
        <h1 className={`App-title-${isTabletOrMobile ? 'mobile': 'desktop'}-${isPortrait ? 'portrait': 'landscape'}`}>$ROAST Chicken</h1>
        <h3 className={`App-slogan-${isTabletOrMobile ? 'mobile': 'desktop'}-${isPortrait ? 'portrait': 'landscape'}`}>Meme coin with a crispy edge on Solana!</h3>
        <div>
          <img className={`App-twitter-${isTabletOrMobile ? 'mobile': 'desktop'}-${isPortrait ? 'portrait': 'landscape'}`} src={twitter} alt="x/twitter" onClick={()=> window.open(twitterLink, "_blank", "noreferrer")} />
          <img className={`App-dexscreener-${isTabletOrMobile ? 'mobile': 'desktop'}-${isPortrait ? 'portrait': 'landscape'}`} src={dexscreener} alt="dexscreener" onClick={()=> window.open(dexscreenerLink, "_blank", "noreferrer")} />
          {/* <img className={`App-coingecko-${isTabletOrMobile ? 'mobile': 'desktop'}-${isPortrait ? 'portrait': 'landscape'}`} src={coingecko} alt="coingecko" onClick={()=> window.open(coingeckoLink, "_blank", "noreferrer")} /> */}
          <img className={`App-jupiter-${isTabletOrMobile ? 'mobile': 'desktop'}-${isPortrait ? 'portrait': 'landscape'}`} src={jupiter} alt="jupiter" onClick={()=> window.open(jupiterLink, "_blank", "noreferrer")} />
        </div>
       
        <p className={`App-disclaimer-${isTabletOrMobile ? 'mobile': 'desktop'}-${isPortrait ? 'portrait': 'landscape'}`}>$ROAST is simply a meme coin with no intrinsic value or expectation of financial return.</p>
        <Game />
        <button className={`App-ca-${isTabletOrMobile ? 'mobile': 'desktop'}-${isPortrait ? 'portrait': 'landscape'}`} onClick={ caClickEvent }>{ `CA: ${caText}` }</button>
        <Toaster position="top-right" reverseOrder={false} />
      </header>
    </div>
  );
}

export default App;
